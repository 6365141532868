import { useEffect, useRef, useState } from "react";
import {
  FaCheck,
  FaEject,
  FaEyeSlash,
  FaPause,
  FaPlay,
  FaStop,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import { floatingTimerBackgroundImages } from "../Settings/TimerBackgroundGallery";
import { motion } from "framer-motion";
import { CgArrowBottomLeftR } from "react-icons/cg";
import {
  setActiveTimerAccumulatedTime,
  setCardModalActive,
  updateCurrentUser,
} from "../../redux/appSlice";
import {
  addTasks,
  moveTaskToBottom,
  moveTaskToBottomOfIncomplete,
  updateTask,
} from "../../redux/tasksSlice";
import { BiSolidHide } from "react-icons/bi";
import { Tooltip } from "antd";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { HiSquare2Stack } from "react-icons/hi2";

export default function FloatingTimer() {
  const [showMessage, setShowMessage] = useState(null);

  const {
    active_timer = null,
    floating_timer_background = "color_black",
    auto_fill_actual_time_disabled = false,
    move_task_on_complete_disabled = false,
    timer_auto_next_task = false,
    floating_timer_enabled = false,
  } = useSelector((state) => state.app.currentUser);

  const userId = useSelector((state) => state.app.uid);
  const task = useSelector((state) => state.tasks?.data[active_timer?.task_id]);

  const tasks = useSelector((state) => state.tasks);
  const taskOrder = useSelector((state) => state.tasks.order);

  const [hoveringOverPause, setHoveringOverPause] = useState(false);
  const { auto_stop_timer = null } = useSelector(
    (state) => state.app.currentUser
  );

  const dispatch = useDispatch();
  const [time, setTime] = useState(0);
  const timerRef = useRef(null);
  const [minimized, setMinimized] = useState(false);

  // Function to get the next incomplete task in the task order and start it
  function startNextTaskIfApplicable() {
    if (timer_auto_next_task) {
      var listId = task.date
        ? moment(task.date.toDate ? task.date.toDate() : task.date).format(
            "YYYY-MM-DD"
          )
        : task.listId
        ? task.listId
        : "brain_dump";

      const currentTaskIndex = taskOrder?.[listId]?.order.indexOf(task.id);
      const nextTask = taskOrder?.[listId]?.order
        .slice(currentTaskIndex + 1)
        .find((taskId) => {
          return tasks.data[taskId] && !tasks.data[taskId].complete;
        });

      clearInterval(timerRef.current);

      if (nextTask && tasks.data[nextTask]) {
        setShowMessage("🙌 Great job! Starting next task...");
        setTimeout(() => {
          dispatch(
            updateCurrentUser({
              newValues: {
                active_timer: {
                  task_id: nextTask,
                  active: true,
                  focus_time_original: 0,
                  focus_time_left: 0,
                  last_start_time: new Date(),
                  time_accumulated: 0,
                  type: "stopwatch",
                },
              },
              previousValues: { active_timer: active_timer },
            })
          );
          setShowMessage(null);
        }, 2000);
      } else {
        setShowMessage("🥳 Great job! No more tasks left");
        setTimeout(() => {
          dispatch(
            updateCurrentUser({
              newValues: { active_timer: null },
              previousValues: { active_timer: active_timer },
            })
          );
          setShowMessage(null);
        }, 2000);
      }
    } else {
      setShowMessage("🥳 Great job! Closing timer now...");
      setTimeout(() => {
        dispatch(
          updateCurrentUser({
            newValues: { active_timer: null },
            previousValues: { active_timer: active_timer },
          })
        );
        setShowMessage(null);
      }, 2000);
    }
  }

  useEffect(() => {
    if (active_timer && active_timer.task_id) {
      const unsub = onSnapshot(
        doc(db, "users", userId, "tasks", active_timer.task_id),
        (doc) => {
          if (!doc.exists()) return;
          var taskTemp = {
            ...doc.data(),
            id: doc.id,
            date: doc.data().date ? doc.data().date.toDate() : null,
          };
          dispatch(
            addTasks({
              tasks: [taskTemp],
              dates: [],
              activeTimerTaskId: taskTemp.id,
            })
          );
        }
      );
      return () => unsub();
    }
  }, [active_timer, userId]);

  useEffect(() => {
    if (active_timer) {
      if (active_timer?.active) {
        clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
          setTime(
            (active_timer.time_accumulated || 0) +
              moment().diff(
                active_timer.last_start_time?.toDate
                  ? active_timer.last_start_time?.toDate()
                  : active_timer.last_start_time,
                "seconds"
              )
          );
        }, 1000);
      } else {
        setTime(active_timer.time_accumulated || 0);
        clearInterval(timerRef.current);
      }
    } else {
      clearInterval(timerRef.current);
      setTime(0);
    }
  }, [active_timer]);

  useEffect(() => {
    dispatch(setActiveTimerAccumulatedTime(time));
  }, [time]);

  const pauseTimer = () => {
    dispatch(
      updateCurrentUser({
        newValues: {
          active_timer: {
            ...active_timer,
            active: false,
            time_accumulated: time,
          },
        },
        previousValues: { active_timer: active_timer },
      })
    );
    clearInterval(timerRef.current);
  };

  const startTimer = () => {
    dispatch(
      updateCurrentUser({
        newValues: {
          active_timer: {
            ...active_timer,
            active: true,
            last_start_time: new Date(),
          },
        },
        previousValues: { active_timer: active_timer },
      })
    );
  };

  const stopTimer = () => {
    setShowMessage("✨ Time saved, closing timer now...");

    clearInterval(timerRef.current);
    setTimeout(() => {
      if (task) {
        dispatch(
          updateTask({
            taskId: task.id,
            currentTask: task,
            newData: { actual_time: (task.actual_time || 0) + time },
          })
        );
      }
      dispatch(
        updateCurrentUser({
          newValues: { active_timer: null },
          previousValues: { active_timer: active_timer },
        })
      );

      setShowMessage(null);
    }, 2000);
  };

  useEffect(() => {
    if (auto_stop_timer === task?.id) {
      stopTimer();
      dispatch(
        updateCurrentUser({
          newValues: { auto_stop_timer: null },
          previousValues: { auto_stop_timer: auto_stop_timer },
        })
      );
    }
  }, [auto_stop_timer, task?.id]);

  function completeTaskForEvent() {
    // Update the task
    var taskId = task?.id;

    if (!taskId) return;

    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          complete: true,
          completed_at: new Date(),
          actual_time: (task.actual_time || 0) + time,
        },
        currentTask: task,
        saveGhostOrder: false,
        ignore_timer_settings: true,
      })
    );

    if (!move_task_on_complete_disabled) {
      // If we are marking as complete, move it to the bottom of the order

      // Get date in format YYYY-MM-DD
      const dateString = moment(task?.date).format("YYYY-MM-DD");

      dispatch(
        moveTaskToBottom({
          taskId: task?.id,
          date: dateString,
        })
      );
    }

    startNextTaskIfApplicable();
  }

  const buttonVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div
      className={`relative ${
        isDesktopApp() && floating_timer_enabled ? "h-[100vh]" : "h-[65px]"
      }  ${
        isDesktopApp() && floating_timer_enabled ? "w-full" : "w-[200px]"
      } bg-cover bg-center overflow-hidden font-mono font-medium`}
      style={{
        backgroundImage: `${
          floatingTimerBackgroundImages[floating_timer_background].image
            ? `url('${floatingTimerBackgroundImages[floating_timer_background].image}')`
            : ""
        }`,
        WebkitAppRegion: "drag",
        backgroundColor: `${
          floatingTimerBackgroundImages[floating_timer_background].background ||
          "#202020"
        }`,
        color: `${
          floatingTimerBackgroundImages[floating_timer_background].color ||
          "#ffffff"
        }`,
      }}
    >
      <div
        className={`absolute inset-0  ${
          floatingTimerBackgroundImages[floating_timer_background].type ===
          "color"
            ? ""
            : "bg-black"
        }
         ${showMessage ? "bg-opacity-80" : "bg-opacity-45"}`}
      ></div>
      {showMessage != null ? (
        <div className="flex items-center absolute inset-0 px-3 text-xs">
          {showMessage}
        </div>
      ) : (
        <div className="flex items-center absolute inset-0 px-3">
          <div
            onMouseEnter={() => setHoveringOverPause(true)}
            onMouseLeave={() => setHoveringOverPause(false)}
            className="cursor-pointer flex flex-row gap-2  items-center absolute right-3 z-40"
            style={{ WebkitAppRegion: "no-drag" }}
          >
            {isDesktopApp() && hoveringOverPause && (
              <Tooltip
                title={
                  floating_timer_enabled
                    ? null
                    : "Enable floating timer (above other windows)"
                }
              >
                <motion.div
                  variants={buttonVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ duration: 0.01 }}
                  onClick={() => {
                    setHoveringOverPause(false);
                    dispatch(
                      updateCurrentUser({
                        newValues: {
                          floating_timer_enabled: !floating_timer_enabled,
                        },
                        previousValues: {
                          floating_timer_enabled: floating_timer_enabled,
                        },
                      })
                    );
                  }}
                  className="rounded-full cursor-pointer bg-white text-neutral-700 shadow-md p-[7px] text-[13px] hover:shadow-lg hover:bg-neutral-200 transition duration-100 ease-in-out"
                  style={{ WebkitAppRegion: "no-drag" }}
                >
                  {floating_timer_enabled ? <FaEyeSlash /> : <HiSquare2Stack />}
                </motion.div>
              </Tooltip>
            )}
            {hoveringOverPause && (
              <motion.div
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                transition={{ duration: 0.01 }}
                onClick={() => {
                  setHoveringOverPause(false);
                  completeTaskForEvent();
                }}
                className="rounded-full cursor-pointer bg-white text-green-500 shadow-md p-[7px] text-[13px] hover:shadow-lg hover:bg-neutral-200 transition duration-100 ease-in-out"
                style={{ WebkitAppRegion: "no-drag" }}
              >
                <FaCheck />
              </motion.div>
            )}
            {hoveringOverPause && (
              <motion.div
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                transition={{ duration: 0.01 }}
                onClick={() => {
                  setHoveringOverPause(false);
                  stopTimer();
                }}
                className="rounded-full cursor-pointer bg-white text-red-500 shadow-md p-[7px] text-[13px] hover:shadow-lg hover:bg-neutral-200 transition duration-100 ease-in-out"
                style={{ WebkitAppRegion: "no-drag" }}
              >
                <FaStop />
              </motion.div>
            )}
            {active_timer?.active ? (
              <div
                onClick={() => {
                  //  setHoveringOverPause(false);
                  pauseTimer();
                }}
                className={`rounded-full cursor-pointer bg-white text-neutral-600 shadow-md p-[7px] text-[13px] hover:shadow-lg hover:bg-neutral-200 transition duration-100 ease-in-out ${
                  floating_timer_background === "color_black"
                    ? "dark:border-neutral-300 dark:border"
                    : ""
                }`}
                style={{ WebkitAppRegion: "no-drag" }}
              >
                <FaPause />
              </div>
            ) : (
              <div
                onClick={() => {
                  //  setHoveringOverPause(false);
                  startTimer();
                }}
                className="rounded-full cursor-pointer bg-white text-neutral-600 shadow-md p-[7px] text-[13px] hover:shadow-lg hover:bg-neutral-200 transition duration-100 ease-in-out"
                style={{ WebkitAppRegion: "no-drag" }}
              >
                <FaPlay />
              </div>
            )}
          </div>
          {hoveringOverPause && (
            <div
              className="absolute inset-0"
              style={{
                background:
                  "linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8))",
              }}
            ></div>
          )}
          <div className="w-full flex flex-col gap-0 items-start">
            <div className="flex items-center justify-between w-full">
              <span className="text-[22px]">
                {!isNaN(time)
                  ? moment
                      .utc(time * 1000)
                      .format(time < 3600 ? "mm:ss" : "HH:mm:ss")
                  : "00:00:00"}
              </span>
            </div>
            <div
              onClick={() => {
                if (!isDesktopApp() || !floating_timer_enabled) {
                  dispatch(setCardModalActive(task?.id));
                }
              }}
              className={`${
                !isDesktopApp() || !floating_timer_enabled
                  ? "cursor-pointer"
                  : ""
              } text-xs w-full overflow-hidden whitespace-nowrap font-sans overflow-ellipsis`}
              style={
                !isDesktopApp() || !floating_timer_enabled
                  ? { WebkitAppRegion: "no-drag" }
                  : {}
              }
            >
              {task?.description?.length > 20
                ? task?.description?.substring(0, 20) + "..."
                : task?.description}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
