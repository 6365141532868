import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import MiniKanbanView from "../Kanban/MiniKanbanView";
import moment from "moment";
import { IoArrowForward } from "react-icons/io5";
import PlannerContainer, { BackButton, NextButton } from "./PlannerContainer";

export default function YesterdayReview({ taskOrderEditable, setStep }) {
  const tasks = useSelector((state) => state.tasks.data);

  const taskOrder = useSelector((state) => state.tasks.order);

  const labels = useSelector((state) => state.labels.data);

  const [yesterdaysTasks, setYesterdaysTasks] = useState([]);

  const [labelBreakdownData, setLabelBreakdownData] = useState([]);

  const [yesterdayDateKey, setYesterdayDateKey] = useState(null);

  const [todayDateKey, setTodayDateKey] = useState(null);

  // Function that will get the tasks from yesterday
  // And then output a breakdown of the labels used
  // Adding up how much actual_time was spent on each label
  // And how much estimated_time was spent on each label
  // and the count of how many tasks
  function getLabelBreakdownData(yesterdaysTasks) {
    const breakdown = {};

    yesterdaysTasks.forEach((task) => {
      // Use task.label if it exists, otherwise use "no_label"
      const label = task.label || "no_label";

      if (!breakdown[label]) {
        breakdown[label] = {
          actual_time: 0,
          estimated_time: 0,
          count: 0,
        };
      }

      breakdown[label].actual_time += task.actual_time || 0;
      breakdown[label].estimated_time += task.estimated_time || 0;
      breakdown[label].count += 1;
    });

    return breakdown;
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours === 0) {
      return `${minutes}m`;
    } else {
      return `${hours}h ${minutes}m`;
    }
  }

  useEffect(() => {
    // Lets get the tasks from yesterday
    try {
      const yesterday = new Date();

      yesterday.setDate(yesterday.getDate() - 1);

      // Yesterday format of "YYYY-MM-DD"
      const yesterdayFormatted = moment(yesterday).format("YYYY-MM-DD");

      setYesterdayDateKey(yesterdayFormatted);

      setTodayDateKey(moment().format("YYYY-MM-DD"));

      const yesterdaysTasks = taskOrder[yesterdayFormatted].order
        .map((taskId) => tasks[taskId])
        .filter((task) => task);

      setYesterdaysTasks(yesterdaysTasks);

      const labelBreakdownData = getLabelBreakdownData(yesterdaysTasks);

      setLabelBreakdownData(labelBreakdownData);
    } catch {
      console.log("No tasks from yesterday");
    }
  }, [tasks, taskOrder]);

  const totalActualTime = Object.values(labelBreakdownData).reduce(
    (acc, curr) => acc + curr.actual_time || 0,
    0
  );

  return (
    <PlannerContainer
      sidebarContent={
        <div
          className="flex flex-col gap-5 justify-start"
          style={{ WebkitAppRegion: "no-drag" }}
        >
          <div className="flex flex-col gap-2 items-start">
            <div className="inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm/5 font-medium sm:text-xs/5 forced-colors:outline bg-violet-500/15 text-violet-700 group-data-[hover]:bg-violet-500/25 dark:text-violet-400 dark:group-data-[hover]:bg-violet-500/20">
              Step 1
            </div>
            <div className="text-xl font-semibold">
              Let's review yesterday's work ✍️
            </div>
          </div>
          <div className="flex flex-col gap-4 justify-start rounded-lg ">
            <div className="flex flex-row gap-2 items-center justify-between">
              <div>
                {yesterdaysTasks.filter((task) => task && task.complete).length}{" "}
                tasks completed
              </div>
              <div className="text-neutral-400">
                {formatTime(totalActualTime)}
              </div>
            </div>

            {/* Single bar chart showing a breakdown of time spent */}

            <div className="flex w-full bg-gray-200 rounded-md overflow-hidden">
              {Object.entries(labelBreakdownData)
                .sort(([labelIdA], [labelIdB]) => {
                  if (labelIdA === "no_label") return 1;
                  if (labelIdB === "no_label") return -1;
                  return 0;
                })
                .map(([labelId, data]) => (
                  <Tooltip
                    key={labelId}
                    title={`${labels[labelId]?.name || "No label"} ${(
                      (data.actual_time / totalActualTime) *
                      100
                    ).toFixed(1)}%`}
                  >
                    <div
                      style={{
                        width: `${(
                          (data.actual_time / totalActualTime) *
                          100
                        ).toFixed(1)}%`,
                        backgroundColor: labels[labelId]?.color || "gray",
                        height: "10px",
                      }}
                    />
                  </Tooltip>
                ))}
            </div>

            <div className="flex flex-col gap-2">
              {Object.keys(labelBreakdownData).map((labelId) => {
                // If labelId is "no_label", use "No Label" instead
                const label = labels[labelId];

                return (
                  <div
                    key={labelId}
                    className="flex flex-row gap-2 items-center justify-between"
                  >
                    <div className="flex flex-row gap-2 items-center text-sm">
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{
                          backgroundColor: label?.color || "gray",
                        }}
                      />
                      {label?.name || "No Label"}
                    </div>
                    <div className="text-neutral-400">
                      {formatTime(labelBreakdownData[labelId].actual_time)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
      mainContent={
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-0 ">
            <MiniKanbanView
              key={yesterdayDateKey}
              orderEditable={taskOrderEditable?.[yesterdayDateKey]?.order}
              date={yesterdayDateKey}
              todayMode={true}
              headerHidden={true}
              disableCompletedOpacity={true}
              customHeader={
                <div className="flex flex-col gap-1 mb-6">
                  <div className="text-xl font-semibold">
                    Finished Yesterday
                  </div>
                  <div className="text-sm text-neutral-500">
                    Feel free to make changes
                  </div>
                </div>
              }
              maxWidth={350}
              ritualMode={true}
            />
          </div>
          <div className="flex flex-col gap-0">
            <MiniKanbanView
              key={todayDateKey}
              orderEditable={taskOrderEditable?.[todayDateKey]?.order}
              date={todayDateKey}
              todayMode={true}
              headerHidden={true}
              disableCompletedOpacity={true}
              customHeader={
                <div className="flex flex-col gap-1 mb-6">
                  <div className="text-xl font-semibold">What you missed</div>
                  <div className="text-sm text-neutral-500">
                    We'll roll these over to today
                  </div>
                </div>
              }
              maxWidth={350}
              ritualMode={true}
            />
          </div>
        </div>
      }
      nextButton={<NextButton onClick={() => setStep(1)} />}
    />
  );
}
