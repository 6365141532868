import React, { useEffect, useState } from "react";
import PlannerContainer, { NextButton } from "./PlannerContainer";
import moment from "moment";
import MiniKanbanView from "../Kanban/MiniKanbanView";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { tasksServerUrl } from "../../utils";
import axios from "axios";
import { setToastVisible } from "../../redux/appSlice";
import { useNavigate } from "react-router-dom";

function ShutdownToday({ taskOrderEditable, setStep }) {
  const [rolloverProcessing, setRolloverProcessing] = useState(false);

  const [todayDateKey, setTodayDateKey] = useState(null);
  const [tomorrowDateKey, setTomorrowDateKey] = useState(null);

  const tasks = useSelector((state) => state.tasks.data);

  const taskOrder = useSelector((state) => state.tasks.order);

  const labels = useSelector((state) => state.labels.data);

  const [todayTasks, setTodayTasks] = useState([]);

  const [labelBreakdownData, setLabelBreakdownData] = useState([]);

  const dispatch = useDispatch();

  const { rollover_position = "bottom" } = useSelector(
    (state) => state.app.currentUser
  );

  const userId = useSelector((state) => state.app.uid);

  function callRollover() {
    setRolloverProcessing(true);
    var systemTimezone = moment.tz.guess();

    // If the systemTimezone is null, default to Central Time
    if (!systemTimezone) {
      systemTimezone = "America/Chicago";
    }

    const tomorrowISO = moment().add(1, "days").toISOString();

    return axios
      .get(`${tasksServerUrl}/rollOverTasksV3`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          systemTimezone: systemTimezone,
          rollOverPosition: rollover_position,
          userId: userId,
          specificDate: tomorrowISO,
        },
      })
      .then((response) => {
        //console.log("rolled over tasks :", response.data);
        setRolloverProcessing(false);
      })
      .catch((error) => {
        console.log("error rolling over tasks :", error);
        setRolloverProcessing(false);
        dispatch(
          setToastVisible({
            toastType: "error",
            message:
              "Something went wrong rolling over tasks. Please contact support",
          })
        );
      });
  }

  useEffect(() => {
    callRollover();
  }, []);

  function getLabelBreakdownData(tasks) {
    const breakdown = {};

    tasks?.forEach((task) => {
      // Use task.label if it exists, otherwise use "no_label"
      const label = task.label || "no_label";

      if (!breakdown[label]) {
        breakdown[label] = {
          actual_time: 0,
          estimated_time: 0,
          count: 0,
        };
      }

      breakdown[label].actual_time += task.actual_time || 0;
      breakdown[label].estimated_time += task.estimated_time || 0;
      breakdown[label].count += 1;
    });

    return breakdown;
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours === 0) {
      return `${minutes}m`;
    } else {
      return `${hours}h ${minutes}m`;
    }
  }

  useEffect(() => {
    try {
      const todayDateKey = moment().format("YYYY-MM-DD");
      setTodayDateKey(todayDateKey);

      const tomorrowDateKey = moment().add(1, "days").format("YYYY-MM-DD");
      setTomorrowDateKey(tomorrowDateKey);

      const todayTasks = taskOrder[todayDateKey]?.order
        .map((taskId) => tasks[taskId])
        .filter((task) => task);

      setTodayTasks(todayTasks);

      const labelBreakdownData = getLabelBreakdownData(todayTasks);

      setLabelBreakdownData(labelBreakdownData);
    } catch {
      console.log("No tasks from today");
    }
  }, [tasks, taskOrder]);

  const totalActualTime = Object.values(labelBreakdownData).reduce(
    (acc, curr) => acc + curr.actual_time || 0,
    0
  );

  const navigate = useNavigate();

  return (
    <>
      {rolloverProcessing ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 backdrop-blur-sm z-[5000]">
          <div className="flex flex-col items-center space-y-4">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 dark:border-white"></div>
            <p className="text-lg font-semibold text-neutral-700 dark:text-white">
              Analyzing data
            </p>
            <button
              onClick={() => {
                navigate("/");
              }}
              className="px-4 text-neutral-700 dark:text-white hover:text-neutral-500 hover:dark:text-neutral-200 rounded-lg  transition-colors"
            >
              (or cancel and go back)
            </button>
          </div>
        </div>
      ) : null}
      <PlannerContainer
        sidebarContent={
          <div
            className="flex flex-col gap-5 justify-start"
            style={{ WebkitAppRegion: "no-drag" }}
          >
            <div className="flex flex-col gap-2 items-start">
              <div className="inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm/5 font-medium sm:text-xs/5 forced-colors:outline bg-violet-500/15 text-violet-700 group-data-[hover]:bg-violet-500/25 dark:text-violet-400 dark:group-data-[hover]:bg-violet-500/20">
                Step 1
              </div>
              <div className="text-xl font-semibold">
                Let's review today's <br /> work ✍️
              </div>
            </div>
            <div className="flex flex-col gap-4 justify-start rounded-lg ">
              <div className="flex flex-row gap-2 items-center justify-between">
                <div>
                  {todayTasks?.filter((task) => task && task.complete).length}{" "}
                  tasks completed
                </div>
                <div className="text-neutral-400">
                  {formatTime(totalActualTime)}
                </div>
              </div>

              {/* Single bar chart showing a breakdown of time spent */}

              <div className="flex w-full bg-gray-200 rounded-md overflow-hidden">
                {Object.entries(labelBreakdownData)
                  .sort(([labelIdA], [labelIdB]) => {
                    if (labelIdA === "no_label") return 1;
                    if (labelIdB === "no_label") return -1;
                    return 0;
                  })
                  .map(([labelId, data]) => (
                    <Tooltip
                      key={labelId}
                      title={`${labels[labelId]?.name || "No label"} ${(
                        (data.actual_time / totalActualTime) *
                        100
                      ).toFixed(1)}%`}
                    >
                      <div
                        style={{
                          width: `${(
                            (data.actual_time / totalActualTime) *
                            100
                          ).toFixed(1)}%`,
                          backgroundColor: labels[labelId]?.color || "gray",
                          height: "10px",
                        }}
                      />
                    </Tooltip>
                  ))}
              </div>

              <div className="flex flex-col gap-2">
                {Object.keys(labelBreakdownData).map((labelId) => {
                  // If labelId is "no_label", use "No Label" instead
                  const label = labels[labelId];

                  return (
                    <div
                      key={labelId}
                      className="flex flex-row gap-2 items-center justify-between"
                    >
                      <div className="flex flex-row gap-2 items-center text-sm">
                        <div
                          className="w-2 h-2 rounded-full"
                          style={{
                            backgroundColor: label?.color || "gray",
                          }}
                        />
                        {label?.name || "No Label"}
                      </div>
                      <div className="text-neutral-400">
                        {formatTime(labelBreakdownData[labelId].actual_time)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
        mainContent={
          <div className="flex flex-row gap-4">
            <div className="flex flex-col gap-0 ">
              <MiniKanbanView
                key={todayDateKey}
                orderEditable={taskOrderEditable?.[todayDateKey]?.order}
                date={todayDateKey}
                todayMode={true}
                headerHidden={true}
                disableCompletedOpacity={true}
                customHeader={
                  <div className="flex flex-col gap-1 mb-6">
                    <div className="text-xl font-semibold">
                      Here’s what you did today
                    </div>
                    <div className="text-sm text-neutral-500">
                      Feel free to make any corrections
                    </div>
                  </div>
                }
                maxWidth={350}
                ritualMode={true}
              />
            </div>
            <div className="flex flex-col gap-0">
              <MiniKanbanView
                key={tomorrowDateKey}
                orderEditable={taskOrderEditable?.[tomorrowDateKey]?.order}
                date={tomorrowDateKey}
                todayMode={true}
                headerHidden={true}
                disableCompletedOpacity={true}
                customHeader={
                  <div className="flex flex-col gap-1 mb-6">
                    <div className="text-xl font-semibold">What you missed</div>
                    <div className="text-sm text-neutral-500">
                      We'll roll these over to tomorrow
                    </div>
                  </div>
                }
                maxWidth={350}
                ritualMode={true}
              />
            </div>
          </div>
        }
        nextButton={<NextButton onClick={() => setStep(1)} />}
      />
    </>
  );
}

export default ShutdownToday;
