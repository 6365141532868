import "./App.css";

import { useEffect, useCallback, useState, useRef } from "react";
import Settings from "./Components/Settings";
import { useDispatch, useSelector } from "react-redux";
import {
  loadInitialDates,
  setCurrentUser,
  setUid,
  loadDatesFromStartDate,
  refreshKabanCursor,
  setToastVisible,
  setLatestVersion,
  updateCurrentUser,
  clearBulkSelect,
  setAppTheme,
  setReminderNotificationsEnabled,
  setTimeboxNotificationsEnabled,
  setTimeboxNotificationsBefore,
  addNotionConnections,
} from "./redux/appSlice";
import { addLabels, removeLabel } from "./redux/labelsSlice";
import {
  addTasks,
  processTaskOrders,
  addBraindumpOrder,
  processRecurringTasks,
  manuallySetTasksLoading,
  removeDatesFromLoaded,
  removeRecurringTask,
  changeCalendarDate,
  addLists,
  addListsOrder,
  cancelDeletion,
} from "./redux/tasksSlice";

import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Analytics from "./Components/Analytics";
import UpgradeModal from "./Components/Upgrade";
import TrialModal from "./Components/Upgrade/TrialModal";
import SubscriptionSuccess from "./Components/Upgrade/Success";
import CalendarSuccess from "./Components/Calendar/CalendarSuccess";
import { useInterval } from "usehooks-ts";

import { getAuth } from "firebase/auth";

import { theme } from "antd";

import _, { orderBy, set } from "lodash";

import debounce from "lodash.debounce";

import moment from "moment-timezone";

import DnDContainer from "./Components/DnDContainer";

import { Default, Mobile } from "./mediaUtils";

import TabBar from "./Components/Mobile/TabBar";

import { db } from "./firebase";

import {
  query,
  where,
  collection,
  onSnapshot,
  doc,
  or,
  and,
} from "firebase/firestore";

import CardModal from "./Components/CardModal";
import CreateTaskModal from "./Components/CardModal/CreateTaskModal";
import SettingsContent from "./Components/Settings/SettingsContent";

import { DndProvider } from "react-dnd";

import MouseBackEnd from "./Components/DnDContainer/MouseBackend";
import CannyAuth from "./Components/Auth/CannyAuth";
import Onboarding from "./Components/Auth/Onboarding";
import { ConfigProvider } from "antd";

import EventEditorModal from "./Components/Calendar/EventEditorModal";
import axios from "axios";
import {
  analytics,
  isDev,
  isElectron,
  isMac,
  notionServerUrl,
  tasksServerUrl,
} from "./utils";

import { IoSparkles } from "react-icons/io5";

import { getVersion } from "@todesktop/client-core/app";
import { app_version } from "./utils";
import KeyboardShortcuts from "./Components/Generics/KeyboardShortcuts";
import { useHotkeys } from "react-hotkeys-hook";
import TodayFocusMode from "./Components/Today";
import { Toaster } from "sonner";
import MobileFtux from "./Components/Generics/MobileFtux";
import Search from "./Components/Search";
import DesktopUpdater from "./Components/Generics/DesktopUpdater";
import UndoDeletion from "./Components/Generics/UndoDeletion";
import KanbanCalendarToggle from "./Components/Generics/KanbanCalendarToggle";
import CalendarMenu from "./Components/Desktop/CalendarMenu";

import Walkthrough from "./Components/Auth/Onboarding/Walkthrough";
import QuickCapture from "./Components/QuickCapture";
import UndoListDeletion from "./Components/Generics/UndoListDeletion";
import Slack from "./Components/OAuth/Slack";
import OAuth from "./Components/OAuth";
import Outlook from "./Components/OAuth/Outlook";
import DesktopFunctions from "./Components/Generics/DesktopFunctions";
import FloatingTimerHelper from "./Components/Generics/FloatingTimerHelper";
import FloatingTimer from "./Components/Generics/FloatingTimer";
import DueDateNotification from "./Components/Desktop/DueDateNotification";
import DailyPlanning from "./Components/Rituals/DailyPlanning";
import KeyboardShortcutNavListener from "./Components/Generics/KeyboardShortcutNavListener";
import TimeboxNotifications from "./Components/Desktop/TimeboxNotifications";
import DailyPlanningNotification from "./Components/Desktop/DailyPlanningNotification";
import NotionAuth from "./Components/OAuth/NotionAuth";

function App({ userId }) {
  const dates = useSelector((state) => state.app.dates);
  const manualTriggerCounter = useSelector(
    (state) => state.app.manualTriggerCounter
  );
  const mobilePageActive = useSelector((state) => state.app.mobilePageActive);

  const name = useSelector((state) => state.app.currentUser.name);
  const last_rollover_date = useSelector(
    (state) => state.app.currentUser.last_rollover_date
  );
  const rollover_position =
    useSelector((state) => state.app.currentUser.rollover_position) || "bottom";
  const rollover_disabled =
    useSelector((state) => state.app.currentUser.rollover_disabled) || false;

  const user_theme =
    useSelector((state) => state.app.currentUser.user_theme) || null;
  const mode = useSelector((state) => state.app.currentUser.mode) || "kanban";
  const timezone =
    useSelector((state) => state.app.currentUser.timezone) || null;
  const active_calendar_type =
    useSelector((state) => state.app.currentUser.active_calendar_type) ||
    "week";
  const posthog_analytics_enabled =
    useSelector((state) => state.app.currentUser.posthog_analytics_enabled) ||
    true;
  const api_version =
    useSelector((state) => state.app.currentUser.api_version) || null;

  const latestVersion = useSelector((state) => state.app.latestVersion);

  const currentUserLoaded = useSelector((state) => state.app.currentUserLoaded);

  const dispatch = useDispatch();

  const [activeTaskQueries, setActiveTaskQueries] = useState([]);

  const [lastRefreshed, setLastRefreshed] = useState(moment());

  const dateRangesLoaded = useSelector((state) => state.tasks.dateRangesLoaded);
  const [shortcutsVisible, setShortcutsVisible] = useState(false);
  const cardModalActiveFor = useSelector(
    (state) => state.app.cardModalActiveFor
  );

  const tasksToBeDeleted = useSelector((state) => state.tasks.tasksToBeDeleted);

  const bulkSelectedTasks = useSelector(
    (state) => state.app.bulkSelectedTasks || []
  );

  useEffect(() => {
    const handleClick = (event) => {
      const bulkSelectContainer = document.getElementById(
        "bulkSelectContainer"
      );
      if (
        bulkSelectedTasks &&
        !event.shiftKey &&
        !(bulkSelectContainer && bulkSelectContainer.contains(event.target)) &&
        bulkSelectedTasks.length > 0
      ) {
        console.log("clearing bulk select");
        dispatch(clearBulkSelect());
      }
    };

    document.addEventListener("click", handleClick);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [dispatch, bulkSelectedTasks]);

  const asciiArt = `
 ______    _       _       _____   ______
|  ____|  | |     | |     |_   _| |  ____|
| |__     | |     | |       | |   | |__   
|  __|    | |     | |       | |   |  __|  
| |____   | |____ | |____  _| |_  | |____ 
|______|  |______||______||_____| |______|
`;

  const lunaArt = `
  ⢀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⢻⣿⡗⢶⣤⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣠⣄
⠀⢻⣇⠀⠈⠙⠳⣦⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣤⠶⠛⠋⣹⣿⡿
⠀⠀⠹⣆⠀⠀⠀⠀⠙⢷⣄⣀⣀⣀⣤⣤⣤⣄⣀⣴⠞⠋⠉⠀⠀⠀⢀⣿⡟⠁
⠀⠀⠀⠙⢷⡀⠀⠀⠀⠀⠉⠉⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⡾⠋⠀⠀
⠀⠀⠀⠀⠈⠻⡶⠂⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣠⡾⠋⠀⠀⠀⠀
⠀⠀⠀⠀⠀⣼⠃⠀⢠⠒⣆⠀⠀⠀⠀⠀⠀⢠⢲⣄⠀⠀⠀⢻⣆⠀⠀⠀⠀⠀
⠀⠀⠀⠀⢰⡏⠀⠀⠈⠛⠋⠀⢀⣀⡀⠀⠀⠘⠛⠃⠀⠀⠀⠈⣿⡀⠀⠀⠀⠀
⠀⠀⠀⠀⣾⡟⠛⢳⠀⠀⠀⠀⠀⣉⣀⠀⠀⠀⠀⣰⢛⠙⣶⠀⢹⣇⠀⠀⠀⠀
⠀⠀⠀⠀⢿⡗⠛⠋⠀⠀⠀⠀⣾⠋⠀⢱⠀⠀⠀⠘⠲⠗⠋⠀⠈⣿⠀⠀⠀⠀
⠀⠀⠀⠀⠘⢷⡀⠀⠀⠀⠀⠀⠈⠓⠒⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⢻⡇⠀⠀⠀
⠀⠀⠀⠀⠀⠈⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣧⠀⠀⠀
⠀⠀⠀⠀⠀⠈⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠉⠁⠀⠀⠀
  `;

  useEffect(() => {
    console.log(asciiArt);
    console.log(lunaArt);
  }, []);

  useHotkeys(
    "shift+F",
    (e) => {
      e.preventDefault();

      if (mode != "today") {
        analytics("View mode changed", {
          source: "Web",
          mode: "today",
        });

        dispatch(
          updateCurrentUser({
            newValues: {
              mode: "today",
            },
            previousValues: {
              mode: mode,
            },
          })
        );
      } else {
        dispatch(
          updateCurrentUser({
            newValues: {
              mode: "kanban",
            },
            previousValues: {
              mode: mode,
            },
          })
        );

        dispatch(refreshKabanCursor(moment().format("YYYY-MM-DD")));
      }
    },
    {
      enabled: true,
    },
    [mode]
  );

  useEffect(() => {
    // Use the Canny SDK to identify the current user of your website
    if (!isDev) {
      const auth = getAuth();
      const user = auth.currentUser;

      if (name) {
        window.Canny("identify", {
          appID: "637d2ce47a741d0ff12b8c61",
          user: {
            // Replace these values with the current user's data
            email: user.email,
            name: name,
            id: userId,
          },
        });
      }
    }
  }, [userId, name]);

  function loadTasksForDate(dates) {
    // date == null is to load tasks for all lists
    // due_date != null means load tasks that have due dates (for deadlines feature)
    const tasksQuery = query(
      collection(db, "users", userId, "tasks"),
      or(
        and(
          where(
            "date",
            ">=",
            moment(dates[0], "YYYY-MM-DD").startOf("day").toDate()
          ),
          where(
            "date",
            "<=",
            moment(dates[dates.length - 1], "YYYY-MM-DD")
              .endOf("day")
              .toDate()
          )
        ),
        where("date", "==", null)
        /*
        and(
          where("due_date", "!=", null),
          where("complete", "!=", true)
        ) */
      )
    );

    const unsubTasks = onSnapshot(tasksQuery, (querySnapshot) => {
      const tasks = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
          if (change.type === "modified") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
          if (change.type === "removed") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
              deleted: true,
            });
          }
        }
      });

      if (tasks && tasks.length > 0) {
        dispatch(addTasks({ tasks: tasks, dates: dates }));
      }
    });

    return unsubTasks;
  }

  useEffect(() => {
    // if cardModalActiveFor is not null, let's load the task from firestore
    if (cardModalActiveFor) {
      const taskRef = doc(db, "users", userId, "tasks", cardModalActiveFor);

      const unsubTask = onSnapshot(taskRef, (doc) => {
        var data = doc.data();

        if (doc.exists && !doc.metadata.hasPendingWrites) {
          if (data) {
            data.id = doc.id;
            data.date = data.date ? data.date.toDate() : null;
            dispatch(addTasks({ tasks: [data] }));
          }
        }
      });

      return () => {
        unsubTask();
      };
    }
  }, [cardModalActiveFor]);

  // Let's pull notion_connections from the user's data
  useEffect(() => {
    const notionConnectionsQuery = query(
      collection(db, "users", userId, "notion_connections")
    );

    const unsubNotionConnections = onSnapshot(
      notionConnectionsQuery,
      (querySnapshot) => {
        const connections = [];

        querySnapshot.docChanges().forEach((change) => {
          console.log(change);
          var doc = change.doc;

          if (change.type === "added") {
            connections.push({ ...doc.data(), id: doc.id });
          }
          if (change.type === "modified") {
            connections.push({ ...doc.data(), id: doc.id });
          }
          if (change.type === "removed") {
            connections.push({ ...doc.data(), id: doc.id, deleted: true });
          }
        });

        console.log(connections);

        if (connections && connections.length > 0) {
          dispatch(addNotionConnections({ connections: connections }));
        }
      }
    );

    return () => {
      unsubNotionConnections();
    };
  }, []);

  function loadTaskOrderForDates(dates) {
    const taskOrderQuery = query(
      collection(db, "users", userId, "task_order"),
      where(
        "date",
        ">=",
        moment(dates[0], "YYYY-MM-DD").startOf("day").toDate()
      ),
      where(
        "date",
        "<=",
        moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate()
      )
    );

    const unsubTaskOrder = onSnapshot(taskOrderQuery, (querySnapshot) => {
      const taskOrdersAdded = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        var data = doc.data();

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            // Let's remove duplicates from the order array
            if (data.order) {
              data.order = data.order.filter((item, index) => {
                return data.order.indexOf(item) === index;
              });
            } else {
              data.order = [];
            }
            taskOrdersAdded.push({ ...data, id: doc.id });
          }
          if (change.type === "modified") {
            // Let's remove duplicates from the order array
            data.order = data.order.filter((item, index) => {
              return data.order.indexOf(item) === index;
            });

            taskOrdersAdded.push({ ...data, id: doc.id });
          }
        }
      });

      // If there are no docs at all, let's purely process the recurring tasks
      if (querySnapshot.docs.length === 0) {
        dispatch(
          processTaskOrders({
            taskOrder: [],
            dates: dates,
            override: true,
          })
        );
      }

      dispatch(removeDatesFromLoaded({ dates }));
      if (taskOrdersAdded && taskOrdersAdded.length > 0) {
        dispatch(
          processTaskOrders({
            taskOrder: taskOrdersAdded,
            dates: dates,
            override: true,
          })
        );
      }
    });

    return unsubTaskOrder;
  }

  function loadLists() {
    const listsQuery = query(
      collection(db, "users", userId, "lists"),
      orderBy("title", "asc")
    );

    const listOrderQuery = query(
      collection(db, "users", userId, "task_order"),
      where("list", "==", true)
    );

    const unsubLists = onSnapshot(listsQuery, (querySnapshot) => {
      const lists = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            lists.push({ ...doc.data(), id: doc.id });
          }
          if (change.type === "modified") {
            lists.push({ ...doc.data(), id: doc.id });
          }
          if (change.type === "removed") {
            lists.push({ ...doc.data(), id: doc.id, deleted: true });
          }
        }
      });

      if (lists && lists.length > 0) {
        dispatch(addLists({ lists: lists }));
      }
    });

    const unsubListOrder = onSnapshot(listOrderQuery, (querySnapshot) => {
      const listOrders = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            listOrders.push({ ...doc.data(), id: doc.id });
          }

          if (change.type === "modified") {
            listOrders.push({ ...doc.data(), id: doc.id });
          }

          if (change.type === "removed") {
            listOrders.push({ ...doc.data(), id: doc.id, deleted: true });
          }

          if (listOrders && listOrders.length > 0) {
            dispatch(addListsOrder({ orders: listOrders }));
          }
        }
      });
    });

    return {
      unsubLists,
      unsubListOrder,
    };
  }

  function loadBraindump() {
    const brainDumpOrderRef = doc(
      db,
      "users",
      userId,
      "task_order",
      "brain_dump"
    );

    const unsubBraindumpOrder = onSnapshot(brainDumpOrderRef, (doc) => {
      var data = doc.data();

      if (doc.exists && !doc.metadata.hasPendingWrites) {
        if (data) {
          data.order =
            data.order?.filter((item, index) => {
              return data.order.indexOf(item) === index;
            }) || [];

          dispatch(addBraindumpOrder(data));
        } else {
          dispatch(
            addBraindumpOrder({
              id: "brain_dump",
              order: [],
            })
          );
        }
      }
    });

    const braindumpQuery = query(
      collection(db, "users", userId, "tasks"),
      where("date", "==", null)
    );

    return { unsubBraindumpOrder };
  }

  // UseEffect that listens in real time to changes on app/config
  useEffect(() => {
    const unsubConfig = loadConfig();
    return () => {
      unsubConfig();
    };
  }, []);

  function loadConfig() {
    const unsubConfig = onSnapshot(doc(db, "app", "config"), (doc) => {
      var data = doc.data();

      if (doc.exists) {
        // Get latest_version from config
        if (data.latest_version) {
          dispatch(setLatestVersion(data.latest_version));
          // Mixpanel track latest version
        }
      }
    });

    return unsubConfig;
  }

  function loadLabels() {
    const labelsQuery = query(collection(db, "users", userId, "labels"));

    const unsubLabels = onSnapshot(labelsQuery, (querySnapshot) => {
      const labels = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        var data = doc.data();

        if (change.type === "added" || change.type === "modified") {
          labels.push({
            ...doc.data(),
            id: doc.id,
          });
        }

        if (change.type === "removed") {
          dispatch(removeLabel({ labelId: change.doc.id }));
        }
      });

      if (labels) {
        dispatch(addLabels({ labels: labels }));
      }
    });

    return unsubLabels;
  }

  function loadCurrentUser() {
    const docRef = doc(db, "users", userId);

    const unsub = onSnapshot(docRef, (doc) => {
      var data = doc.data();

      if (data) {
        var dataCopy = _.cloneDeep(data);

        dispatch(setCurrentUser(dataCopy));
      } else {
        dispatch(setCurrentUser({}));
      }
    });

    return unsub;
  }

  function loadRecurringTasks(dates) {
    const recurringTasksQuery = query(
      collection(db, "users", userId, "recurring_tasks"),
      orderBy("created_at", "asc")
    );

    const unsubRecurringTasks = onSnapshot(
      recurringTasksQuery,
      (querySnapshot) => {
        const recurringTasksAdded = [];
        const recurringTasksModified = [];

        querySnapshot.docChanges().forEach((change) => {
          var doc = change.doc;
          if (!doc.metadata.hasPendingWrites) {
            if (change.type === "added") {
              recurringTasksAdded.push({
                ...doc.data(),
                id: doc.id,
              });
            }
            if (change.type === "modified") {
              recurringTasksModified.push({
                ...doc.data(),
                id: doc.id,
              });
            }
            if (change.type === "removed") {
              dispatch(
                removeRecurringTask({
                  recurringTask: {
                    ...doc.data(),
                    id: doc.id,
                  },
                })
              );
            }
          }
        });

        if (recurringTasksAdded && recurringTasksAdded.length > 0) {
          dispatch(
            processRecurringTasks({
              recurringTasks: recurringTasksAdded,
              dates: dates,
              override: false,
            })
          );
        }

        if (recurringTasksModified && recurringTasksModified.length > 0) {
          dispatch(
            processRecurringTasks({
              recurringTasks: recurringTasksModified,
              dates: dates,
              override: true,
            })
          );
        }
      }
    );

    return unsubRecurringTasks;
  }

  // If this is not a Mac, change ::-webkit-scrollbar width to 7px
  useEffect(() => {
    if (!isMac()) {
      const style = document.createElement("style");
      style.innerHTML = `
      ::-webkit-scrollbar {
        width: 7px;
      }
    `;
      document.head.appendChild(style);

      // Clean up the appended style when the component unmounts
      return () => {
        document.head.removeChild(style);
      };
    }
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(setUid(userId));
    }
    const unsubCurrentUser = loadCurrentUser();

    const unsubLabels = loadLabels();

    const { unsubBraindumpOrder } = loadBraindump();

    const { unsubLists, unsubListOrder } = loadLists();

    return () => {
      unsubBraindumpOrder();
      unsubCurrentUser();
      unsubLabels();
      unsubLists();
      unsubListOrder();
    };
  }, [dispatch, userId, manualTriggerCounter]);

  useEffect(() => {
    // active_calendar_type change, reload initial dates
    dispatch(loadInitialDates({ active_calendar_type, mode }));
  }, [active_calendar_type]);

  function reloadData(dates) {
    const unsubTasks = loadTasksForDate(dates);

    const unsubTaskOrder = loadTaskOrderForDates(dates);

    const unsubRecurringTasks = loadRecurringTasks(dates);

    setActiveTaskQueries((activeTaskQueries) => {
      // Iterate through the active task queries and unsubscribe from them
      activeTaskQueries.forEach((unsub) => {
        unsub();
      });

      // Add the new ones to the list
      return [unsubTasks, unsubTaskOrder, unsubRecurringTasks];
    });
  }

  // Function to check latest api_version
  // if less than 2, we need to update user and set it
  function checkAPIVersion() {
    if (api_version < 2) {
      dispatch(
        updateCurrentUser({
          newValues: {
            api_version: 2,
          },
          previousValues: {
            api_version: api_version,
          },
        })
      );
    }
  }

  function callRollover() {
    var systemTimezone = moment.tz.guess();
    var systemOffset = moment.tz(systemTimezone).utcOffset();

    // If the systemTimezone is null, default to Central Time
    if (!systemTimezone) {
      systemTimezone = "America/Chicago";
      systemOffset = -300;
    }

    return axios
      .get(`${tasksServerUrl}/rollOverTasksV3`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          systemTimezone: systemTimezone,
          rollOverPosition: rollover_position,
          userId: userId,
        },
      })
      .then((response) => {
        //console.log("rolled over tasks :", response.data);
      })
      .catch((error) => {
        console.log("error rolling over tasks :", error);
        dispatch(
          setToastVisible({
            toastType: "error",
            message:
              "Something went wrong rolling over tasks. Please contact support",
          })
        );
      });
  }

  function checkIfTimezoneChanged() {
    // Using moment
    var systemTimezone = moment.tz.guess();

    // If the systemTimezone is null, default to Central Time
    if (!systemTimezone) {
      systemTimezone = "America/Chicago";
    }

    // If the timezone has changed, let's update the user's timezone
    if (systemTimezone !== timezone) {
      dispatch(
        updateCurrentUser({
          newValues: {
            timezone: systemTimezone,
          },
          previousValues: {
            timezone: timezone,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (currentUserLoaded && !isDev) {
      checkIfTimezoneChanged();

      checkAPIVersion();
    }
  }, [currentUserLoaded]);

  useEffect(() => {
    // If the last rollover date is not today, roll over the tasks
    if (currentUserLoaded && rollover_disabled === false && !isDev) {
      if (last_rollover_date !== moment().startOf("day").format("YYYY-MM-DD")) {
        console.log("calling rollover");
        callRollover();
      }
    }
  }, [last_rollover_date, currentUserLoaded, dispatch]);

  const debouncedSave = useCallback(
    debounce((dates) => reloadData(dates), 250),
    [] // will be created only once initially
  );

  useEffect(() => {
    debouncedSave(dates);
  }, [dates, debouncedSave]);

  useInterval(
    () => {
      // Your custom logic here

      // If the lastRefreshed date is less than the start of today, let's refresh the data
      if (lastRefreshed.toDate() < moment().startOf("day").toDate()) {
        console.log("WE HAVE A REFRESH");
        dispatch(
          changeCalendarDate({
            date: moment(new Date()).format("YYYY-MM-DD"),
          })
        );
        dispatch(
          loadDatesFromStartDate({
            date: new Date().toString(),
            type: active_calendar_type,
            mode: mode,
          })
        );
        dispatch(refreshKabanCursor(moment(new Date()).format("YYYY-MM-DD")));

        setLastRefreshed(moment());
        console.log("REFRESHED");

        // If the last rollover date is not today, roll over the tasks
        if (currentUserLoaded && rollover_disabled === false) {
          if (
            last_rollover_date !== moment().startOf("day").format("YYYY-MM-DD")
          ) {
            callRollover();
          }
        }
      }
    },
    // Delay in milliseconds or null to stop it
    // Default to 1 hour
    1000 * 60
  );

  const [userTheme, setUserTheme] = useState("light");

  // useRef that tracks user_theme, so i can use in closure
  const userThemeRef = useRef(user_theme);

  // Anytime the user_theme changes, let's update the userTheme state
  useEffect(() => {
    userThemeRef.current = user_theme;
  }, [user_theme]);

  useEffect(() => {
    // If there is non user_theme, check local storage for last used
    if (!user_theme) {
      const localTheme = localStorage.getItem("ellie_theme");
      if (localTheme) {
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", (e) => {
            if (!userThemeRef.current || userThemeRef.current == "system") {
              setUserTheme(e.matches ? "dark" : "light");
              // Set the ref

              localStorage.setItem("ellie_theme", e.matches ? "dark" : "light");
            }
          });

        setUserTheme(localTheme);
      } else {
        // Lets just default to system
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", (e) => {
            setUserTheme(e.matches ? "dark" : "light");

            localStorage.setItem("ellie_theme", e.matches ? "dark" : "light");
          });

        setUserTheme(
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
        );

        // If user_theme is system, let's save it to local storage
        localStorage.setItem(
          "ellie_theme",
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
        );
      }
    } else {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => {
          if (user_theme == "system") {
            setUserTheme(e.matches ? "dark" : "light");
          }
        });

      // Setup dark/light mode for the first time

      if (user_theme == "system") {
        setUserTheme(
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
        );

        // If user_theme is system, let's save it to local storage
        localStorage.setItem(
          "ellie_theme",
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
        );
      } else {
        setUserTheme(user_theme);

        // If user_theme is not system, let's save it to local storage
        localStorage.setItem("ellie_theme", user_theme);
      }

      // Remove listener
      return () => {
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .removeEventListener("change", () => {});
      };
    }
  }, [user_theme]);

  useEffect(() => {
    document.body.setAttribute("data-theme", userTheme);

    dispatch(setAppTheme(userTheme));

    if (userTheme == "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [userTheme]);

  if (!currentUserLoaded) {
    return null;
  }
  return (
    <ConfigProvider
      theme={{
        hashed: false,
        algorithm:
          userTheme == "dark" ? theme.darkAlgorithm : theme.lightAlgorithm,
      }}
    >
      <Toaster
        theme={userTheme === "light" ? "light" : "dark"}
        position={tasksToBeDeleted ? "bottom-right" : "top-right"}
        closeButton
        className="z-[1000000"
        richColors
      />
      <div>
        <Router>
          <KeyboardShortcuts
            visible={shortcutsVisible}
            setVisible={setShortcutsVisible}
          />

          <AppSupplementContent />

          <Routes>
            <Route
              path="*"
              element={
                <DndProvider debugMode={true} backend={MouseBackEnd}>
                  <UpgradeModal />
                  <TrialModal />
                  <EventEditorModal />
                  <CreateTaskModal />
                  <Default>
                    <DnDContainer />
                  </Default>
                  {!isElectron() &&
                    !isDesktopApp() &&
                    latestVersion > app_version && (
                      <div className="update-avaialble-card">
                        <div className="title">
                          <IoSparkles className="icon" />
                          <span>New version is available</span>
                        </div>
                        <div
                          onClick={() => {
                            window.location.reload();
                          }}
                          className="update-button"
                        >
                          Refresh and update
                        </div>
                      </div>
                    )}
                  {isDesktopApp() && <DesktopUpdater />}

                  <Mobile>
                    <div className="mobile-container">
                      <div className="mobile-content">
                        {mobilePageActive === "settings" ? (
                          <div className="mobile-settings-page">
                            <SettingsContent />
                          </div>
                        ) : (
                          <DnDContainer />
                        )}
                      </div>
                      <div className="tabbar-container">
                        <TabBar />
                      </div>
                    </div>
                  </Mobile>
                </DndProvider>
              }
            />

            <Route path="slack-oauth" element={<Slack />} />
            <Route path="outlook-oauth" element={<Outlook />} />

            <Route
              path="analytics"
              element={<Analytics userTheme={userTheme} />}
            />

            <Route
              path="floating-timer"
              element={<FloatingTimer userTheme={userTheme} />}
            />

            <Route path="signup" element={<Onboarding />} />

            <Route path="walkthrough" element={<Walkthrough />} />

            <Route path="canny-sso" element={<CannyAuth userId={userId} />} />
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  );
}

function AppSupplementContent() {
  const location = useLocation();
  const isSpecificRoute = location.pathname === "/floating-timer";

  const due_date_settings = useSelector(
    (state) => state.app.currentUser.power_feature_settings?.due_dates || {}
  );

  const dispatch = useDispatch();

  const reminderNotificationsEnabled = useSelector(
    (state) => state.app.reminderNotificationsEnabled
  );

  const timeboxNotificationsEnabled = useSelector(
    (state) => state.app.timeboxNotificationsEnabled
  );

  const daily_planning_enabled = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.daily_planning_enabled
  );

  useEffect(() => {
    let storedValue = localStorage.getItem(
      "ellie_reminder_notifications_enabled"
    );

    // If storedValue is null, set it to "true"
    if (storedValue === null) {
      storedValue = "true";
    }

    const enabled = storedValue === "true" ? true : false;

    dispatch(setReminderNotificationsEnabled(enabled));
  }, []);

  useEffect(() => {
    let storedValue = localStorage.getItem(
      "ellie_timebox_notifications_enabled"
    );

    // If storedValue is null, set it to "true"
    if (storedValue === null) {
      storedValue = "false";
    }

    const enabled = storedValue === "true" ? true : false;

    let storedBeforeTime =
      localStorage.getItem("ellie_timebox_notifications_before_seconds") ||
      10 * 60;

    dispatch(
      setTimeboxNotificationsBefore(parseInt(storedBeforeTime, 10) || 10 * 60)
    );

    dispatch(setTimeboxNotificationsEnabled(enabled));
  }, []);

  return (
    <>
      {!isSpecificRoute && (
        <>
          {isDesktopApp() && <DesktopFunctions />}
          {isDesktopApp() && <FloatingTimerHelper />}

          {isDesktopApp() && isMac() && (
            <Mobile>
              <div className="mini-electron-topbar" />
            </Mobile>
          )}

          <KeyboardShortcutNavListener />
          <Search />
          <QuickCapture />
          <SubscriptionSuccess />
          <CalendarSuccess />
          <NotionAuth />
          {!isDesktopApp() && <MobileFtux />}

          {isDesktopApp() &&
            due_date_settings?.notifications_enabled &&
            reminderNotificationsEnabled && <DueDateNotification />}

          {isDesktopApp() && timeboxNotificationsEnabled && (
            <TimeboxNotifications />
          )}
          {isDesktopApp() && daily_planning_enabled && (
            <DailyPlanningNotification />
          )}
          <CardModal />
          <Settings />
          <UndoDeletion />
          <UndoListDeletion />
          <KanbanCalendarToggle />
          {isDesktopApp() && isMac() && <CalendarMenu />}
        </>
      )}
    </>
  );
}

export default App;
