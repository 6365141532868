import { Switch } from "antd";
import CustomDropdown from "./CustomDropdown";
import { useEffect, useState } from "react";
import _, { property } from "lodash";

const DynamicFilter = ({
  availableProperties,
  onChange,
  disabled,
  filter = {},
  notionUserId,
  clearAction,
}) => {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  // Function to get a notion value from a notion property
  const getNotionValue = (property) => {
    if (property.type === "people") {
      return notionUserId;
    } else if (property.type === "checkbox") {
      return property?.checkbox?.equals || false;
    } else if (property.type === "select") {
      return property?.select?.equals || null;
    } else if (property.type === "multi_select") {
      return property?.multi_select?.contains || null;
    } else if (property.type === "status") {
      return property?.status?.equals || null;
    }
  };

  useEffect(() => {
    let property, value;

    if (filter && Object.values(filter).length > 0) {
      if (filter?.when) {
        // This is for mapped fields
        property = availableProperties.find(
          (prop) => prop.key === filter.when.notion_property
        );

        value = filter.when.notion_value;
      } else if (filter?.notion_property)
        // This is for a pure mapped field (Date only for now)
        property = availableProperties.find(
          (prop) => prop.key === filter.notion_property
        );
      // No value
      else {
        // Its in this format
        /* {
        property: "title",
        select: { equals: "Test" },
        _propertyType: "select",
        _propertyName: "Title",
        _valueName: "Test",
      } */

        property = availableProperties.find(
          (prop) => prop.id === filter.property
        );

        if (property) {
          value = filter._valueId;
        }
      }
    }

    if (property) {
      setSelectedProperty(property);
    }

    if (value) {
      setSelectedValue(value);
    }

    // If both are not, remove the selected property and value
    if (!property && !value) {
      setSelectedProperty(null);
      setSelectedValue(null);
    }
  }, [filter, availableProperties]);

  console.log(availableProperties);

  // useEffect if availableProperties changes, but initialValue does not
  // Update selectedProperty
  // useEffect(() => {
  //   if (
  //     availableProperties.length > 0 &&
  //     selectedProperty &&
  //     Object.values(selectedProperty).length > 0
  //   ) {
  //     const property = availableProperties.find(
  //       (prop) => prop.key === selectedProperty.key
  //     );
  //     if (property && !_.isEqual(property, selectedProperty)) {
  //       setSelectedProperty(property);
  //     }
  //   }
  // }, [availableProperties]);

  useEffect(() => {
    if (selectedProperty && Object.values(selectedProperty).length > 0) {
      let valueToSet = selectedValue;

      if (selectedProperty.type === "people" && selectedValue === null) {
        valueToSet = notionUserId;
        setSelectedValue(notionUserId);

        onChange({
          property: selectedProperty.id,
          people: { contains: notionUserId },
          _propertyType: selectedProperty.type,
          _propertyName: selectedProperty.name,
          _valueName: notionUserId,
          _valueId: notionUserId,
        });
      } else if (
        selectedProperty.type === "checkbox" &&
        selectedValue === null
      ) {
        valueToSet = false;
        setSelectedValue(false);

        onChange({
          property: selectedProperty.id,
          checkbox: { equals: false },
          _propertyType: selectedProperty.type,
          _propertyName: selectedProperty.name,
          _valueName: false,
          _valueId: false,
        });
      } else if (selectedProperty.type === "date" && selectedValue === null) {
        valueToSet = false;
        setSelectedValue(false);

        onChange({
          property: selectedProperty.id,
          date: {},
          _propertyType: selectedProperty.type,
          _propertyName: selectedProperty.name,
          _valueName: false,
          _valueId: false,
        });
      } else {
        if (valueToSet !== null) {
          const { propertyName, valueName } = getNotionPropertyNameAndValue(
            selectedProperty.id,
            valueToSet
          );

          if (
            propertyName === undefined ||
            propertyName === null ||
            valueName === undefined ||
            valueName === null
          )
            return;

          onChange({
            property: selectedProperty.id,
            [selectedProperty.type]: {
              [selectedProperty.type === "multi_select" ||
              selectedProperty.type === "people"
                ? "contains"
                : "equals"]: valueToSet,
            },
            _propertyType: selectedProperty.type,
            _propertyName: propertyName,
            _valueName: valueName,
            _valueId: valueToSet,
          });
        }
      }
    }
  }, [selectedProperty, selectedValue]);

  // Function to get a property name and value name in Notion
  const getNotionPropertyNameAndValue = (propertyId, valueId) => {
    const prop = availableProperties.find((p) => p.id === propertyId);

    if (!prop) return {};

    let valueName;
    switch (prop.type) {
      case "checkbox":
        valueName = valueId ? true : false;
        break;
      case "select":
        valueName = prop.select.options.find(
          (option) => option.id === valueId
        )?.name;
        break;
      case "multi_select":
        valueName = prop.multi_select.options.find(
          (option) => option.id === valueId
        )?.name;
        break;
      case "status":
        valueName = prop.status.options.find(
          (option) => option.id === valueId
        )?.name;
        break;
      case "people":
        valueName = notionUserId;
        break;
      default:
        valueName = valueId;
    }

    return {
      propertyName: prop.name,
      valueName,
    };
  };

  const handlePropertyChange = (selected) => {
    setSelectedProperty(selected);
  };

  const handleValueChange = (value) => {
    setSelectedValue(value);
  };

  const renderValueSelector = () => {
    if (!selectedProperty) return null;

    switch (selectedProperty.type) {
      case "checkbox":
        return (
          <Switch
            checked={selectedValue}
            onChange={(checked) => handleValueChange(checked)}
          />
        );
      case "multi_select":
      case "select":
      case "status":
        return (
          <CustomDropdown
            options={
              selectedProperty[selectedProperty.type]?.options.map(
                (option) => ({
                  key: option.id,
                  label: option.name,
                })
              ) || []
            }
            onSelect={(selected) => handleValueChange(selected.key)}
            defaultValue="Select"
            value={selectedValue}
            className="w-5/12"
            disabled={disabled}
          />
        );
      case "people":
        return <div className="text-sm font-medium">assigned to me</div>;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-row gap-4 items-center">
      <CustomDropdown
        options={availableProperties}
        onSelect={handlePropertyChange}
        defaultValue="Select property"
        value={selectedProperty?.key || null}
        className="w-6/12"
        leadingIcon={
          <img
            className="h-5 w-5 rounded-md"
            src={require("../../../images/Notion_app_logo.png")}
            alt="Notion Logo"
          />
        }
        disabled={disabled}
      />
      {selectedProperty && (
        <>
          {!clearAction && (
            <div>
              {selectedProperty.type === "date"
                ? ""
                : selectedProperty.type === "people"
                ? "is"
                : selectedProperty.type === "multi_Select"
                ? "contains"
                : "is"}
            </div>
          )}
          {renderValueSelector()}
          {clearAction && (
            <div
              onClick={() => {
                clearAction();
              }}
              className="text-sm font-medium cursor-pointer text-blue-600 hover:text-blue-800"
            >
              Clear
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DynamicFilter;
