import React, { useState, useEffect, useRef } from "react";
import { PlayIcon, PauseIcon, StopIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { updateCurrentUser } from "../../../../../redux/appSlice";
import { updateTask } from "../../../../../redux/tasksSlice";
import { MdOutlineTimer } from "react-icons/md";

export default function ActiveTimerIndicator({ item, mode = "card" }) {
  const { active_timer = null } = useSelector((state) => state.app.currentUser);
  const active_timer_task = useSelector(
    (state) => state.tasks?.data[active_timer?.task_id]
  );
  const dispatch = useDispatch();
  const [time, setTime] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    if (active_timer && active_timer?.task_id === item?.id) {
      if (active_timer?.active) {
        clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
          setTime(
            (active_timer.time_accumulated || 0) +
              moment().diff(
                active_timer.last_start_time?.toDate
                  ? active_timer.last_start_time?.toDate()
                  : active_timer.last_start_time,
                "seconds"
              )
          );
        }, 1000);
      } else {
        setTime(active_timer.time_accumulated || 0);
        clearInterval(timerRef.current);
      }
    } else {
      clearInterval(timerRef.current);
      setTime(0);
    }
  }, [active_timer, item?.id]);

  const pauseTimer = () => {
    dispatch(
      updateCurrentUser({
        newValues: {
          active_timer: {
            ...active_timer,
            active: false,
            time_accumulated: time,
          },
        },
        previousValues: { active_timer },
      })
    );
    clearInterval(timerRef.current);
  };

  const startTimer = () => {
    dispatch(
      updateCurrentUser({
        newValues: {
          active_timer: {
            ...active_timer,
            active: true,
            last_start_time: new Date(),
          },
        },
        previousValues: { active_timer },
      })
    );
  };

  if (
    !active_timer ||
    !active_timer_task ||
    active_timer?.task_id !== item?.id
  ) {
    return null;
  }

  return (
    <div
      className={`timer-indicator flex flex-row gap-1 items-center ${mode == "card" ? "mx-[-12px] mb-[-11px] mt-[8px] font-medium p-[8px] px-[14px]" : "p-[10px] px-[14px] font-medium text-sm"} text-indigo-700 dark:text-white bg-indigo-50 dark:bg-neutral-600`}
      data-no-dnd="true"
    >
      <MdOutlineTimer className="w-3.5 h-3.5" />
      <span>
        Timer {active_timer?.active ? "active" : "paused"} (
        {moment.utc(time * 1000).format(time < 3600 ? "mm:ss" : "HH:mm:ss")})
      </span>
    </div>
  );
}
